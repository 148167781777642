<template>
  <RevLoadingScreen
    data-qa="loading-screen"
    data-test="loading-screen"
    text=""
  />
</template>

<script lang="ts" setup>
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useLogin } from '@backmarket/nuxt-module-oauth/useLogin'
import { RevLoadingScreen } from '@ds/components/LoadingScreen'

const logger = useLogger()

useLogin({
  onError(error) {
    if (error instanceof Error) {
      logger.error('Something failed while logging in', { error })
    }
  },
})
</script>
