import { useRuntimeConfig } from '#imports'
import { onMounted } from 'vue'

import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

import { scopedStorage } from '../storage'

import { useAuthOrigin } from './useAuthOrigin'
import { useAuthState, useStoredAuthState } from './useAuthState'
import { useEndpoints } from './useEndpoints'
import { usePkce, useStoredPkceVerifier } from './usePkce'
import { useQueryParam } from './useQueryParam'
import { NEXT_KEY, useRedirect } from './useRedirect'
import { useRevokeToken } from './useRevokeToken'
import { useSession } from './useSession'
import { useTokens } from './useTokens'
import { useUserStore } from './useUserStore'

const AUTH_ENDPOINT = '/auth/login'

/**
 * This represents the case where the random state passed to and eventually
 * received from Hydra does not match the one stored inside our shared storage.
 */
class InvalidStateError extends Error {}

/**
 * Handle the whole login flow, from A to Z.
 *
 * The `useLogin` composable is the single entry point to easily manage whole
 * authentication flows based on the Identity stack. It makes sure that people
 * are redirected to Auth UI where they can create a new account or sign in
 * with an existing one. It also handles the step where people come back from
 * Auth UI with tokens, and exchange them into an actual Badoom session.
 *
 * Note that no monitoring is included by default, this is up to the consumer
 * to provide their own by passing an `onError` parameter.
 *
 * @example
 * const logger = useLogger()
 *
 * useLogin({
 *   onError(error) {
 *     logger.error('Something went wrong', { error })
 *   }
 * })
 *
 * The `useLogin` composable also manages the redirection after a successful
 * login. By default, it will redirect to the `defaultRedirectLocationWhenAuthenticated` value
 * passed when initialising the OAuth module. But, it can be overriden by
 * setting a `next` query parameter into the URL.
 *
 * @example https://www.backmarket.com/auth/login?next=/dashboard
 *
 * External links are also supported in the `next` query parameter. However,
 * they must be first allowed when instantiating the module on the consumer
 * application. This is achieved via the `allowedExternalDomains` option.
 *
 * @example https://www.backmarket.com/auth/login?next=https://www.partner.com
 */
export function useLogin({ onError }: { onError: (error: unknown) => void }) {
  onMounted(async () => {
    try {
      const { location } = window
      const authOrigin = useAuthOrigin()
      const { clientId } = useRuntimeConfig().public.oauth
      const redirectUrl = new URL(location.pathname, location.origin)

      // If there is no `next` parameter, there is no need to store anything.
      // We simply redirect to the fallback page when coming back to this route.
      const next = useQueryParam('next')
      const type = useQueryParam('type')
      if (next !== null) {
        scopedStorage.setItem(NEXT_KEY, JSON.stringify({ next, type }))
      }

      // The `code` query parameter is missing when we visit the login page for
      // the first time in the session (vs. going back from the identity stack).
      const code = useQueryParam('code')
      if (code === null) {
        const pkce = usePkce()
        const state = useAuthState()

        const authUrl = new URL(AUTH_ENDPOINT, authOrigin)

        authUrl.searchParams.append('bm_platform', 'web')
        authUrl.searchParams.append('code_challenge', pkce.challenge)
        authUrl.searchParams.append('code_challenge_method', 'S256')
        authUrl.searchParams.append('redirect_uri', redirectUrl.href)
        authUrl.searchParams.append('response_type', 'code')
        authUrl.searchParams.append('scope', 'offline_access')
        authUrl.searchParams.append('state', state)
        authUrl.searchParams.append('client_id', clientId)

        const journey = useQueryParam('bm_journey')
        if (journey !== null) {
          authUrl.searchParams.append('bm_journey', journey)
        }

        const info = useQueryParam('info')
        if (info !== null) {
          authUrl.searchParams.append('info', info)
        }

        // We always redirect to an external URL (accounts.backmarket.tld), so
        // there is no need to use the navigation module in this specific case.
        location.assign(authUrl)

        return
      }

      const state = useQueryParam('state')
      const storedState = useStoredAuthState()

      if (state !== storedState) {
        throw new InvalidStateError('State and stored state do not match')
      }

      const verifier = useStoredPkceVerifier()
      const endpoints = await useEndpoints(authOrigin)

      const tokenBody = { code, clientId, redirectUrl, verifier }
      const tokens = await useTokens(endpoints.tokenEndpoint, tokenBody)

      await useSession(tokens.accessToken)
      await useRevokeToken(endpoints.revocationEndpoint, {
        refreshToken: tokens.refreshToken,
        clientId,
      })

      try {
        // This is a workaround to reduce the number of cases where a user ends up with an invalid csrftoken.
        // Cf. https://backmarket.atlassian.net/browse/FRONT-1259 for more information.
        // Note that it is still theoretically possible to have a `fetchUser` request made before the `useSession` and end after this new `fetchUser`.
        // TODO: [FRONT-1259] Replace this workaround with a proper fix.
        const userStore = useUserStore()
        await userStore.fetchUser()
      } catch (error) {
        useLogger().error('[AUTH] Failed to fetch bm/user/auth', {
          error: error as Error,
        })
      }

      useRedirect()
    } catch (error) {
      onError(error)

      // If anything goes wrong, we want to redirect the user to the next page
      // anyway. It will likely retrigger a chain of redirects and they will
      // end up on the login page once again, but with fresh data this time.
      useRedirect()
    }
  })
}
