import { useRouter, useRuntimeConfig } from '#imports'

import { scopedStorage } from '../storage'

type StoredParams = {
  next: string | null
  type: string | null
}

export const NEXT_KEY = 'redirection'

/**
 * This custom error represents the case where an absolute URL is passed as
 * next query parameter, but is not allowed based on the module configuration.
 *
 * @see `allowedExternalDomains` module configuration property.
 */
export class NextLocationNotAllowedError extends Error {}

/**
 * Redirect the user to the next page. The next page location is computed from
 * the next query parameter, or fallbacks to the default route location defined
 * using `defaultRedirectLocationWhenAuthenticated` in the module configuration.
 *
 * @throws {NextLocationNotAllowedError} when the next location is not allowed.
 */
export function useRedirect() {
  const { location } = window

  const router = useRouter()
  const config = useRuntimeConfig().public.oauth
  const stored = scopedStorage.getItem(NEXT_KEY)

  if (stored === null) {
    const redirect = router.resolve(
      config.defaultRedirectLocationWhenAuthenticated,
    )
    location.assign(redirect.href)

    return
  }

  const { next } = JSON.parse(stored) as StoredParams

  if (next === null) {
    const redirect = router.resolve(
      config.defaultRedirectLocationWhenAuthenticated,
    )
    location.assign(redirect.href)

    return
  }

  // The second parameter passed to `new URL` is taken into account only when
  // the first parameter is a relative path. If we pass an absolute URL as
  // first parameter, then the URL origin will be defined using this
  // absolute URL, not the second parameter.
  //
  // For example:
  //   new URL('/foo', 'https://xyz.com')                // https://xyz.com/foo
  //   new URL('https://bar.com/foo', 'https://xyz.com') // https://bar.com/foo
  const redirect = new URL(next, location.origin)

  if (
    redirect.origin !== location.origin &&
    config.allowedExternalDomains.includes(redirect.origin) === false
  ) {
    throw new NextLocationNotAllowedError(`${redirect.origin} is not allowed`)
  }

  // We should not need to call `URL#toString` here, but I could not find any
  // way to assess that `location.assign` was called with the proper argument
  // from the test suite. /shrug
  location.assign(new URL(next, location.origin).toString())
}
